import React, { Component } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default class SiteFooter extends Component {
	constructor(props) {
		super(props)
		this.links = this.props.links
		this.render_links = this.render_links.bind(this)
		this.query_links = this.query_links.bind(this)
	}

	footerDataLayer = () => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: "footer_menu",
		})
	}
	componentDidMount() {
		const script = document.createElement("script");
		script.setAttribute("charset", "utf-8");
		script.setAttribute("type", "text/javascript");
		script.src = "https://js.hsforms.net/forms/embed/v2.js";
		script.async = false;
		
		// Only create the form _after_ the Hubspot JS is loaded.
		script.onload = () => {
			if (typeof hbspt !== 'undefined') {
				hbspt.forms.create({
					portalId: "44050800",
					formId: "05789eab-ea9e-4f30-a0b4-a01a889b5161",
					region: "na1",
					target: '.email-form-container',
					onFormReady: function ($form) {
						// Create a wrapper div to hold the arrow button HTML
						const arrowWrapper = document.createElement('div');
						// Insert the exact rendered HTML
						arrowWrapper.innerHTML = `
						  <span tabindex="-1" id="newsletter-arrow" class="arrow-button black right">
							<button aria-label="subscribe button"></button>
							<span class="text">subscribe</span>
							<div class="arrow">
							  <div class="arrow-shaft"></div>
							  <div class="arrow-head">
								<svg width="8px" height="16px" viewBox="0 0 8 16" version="1.1">
								  <path d="M5.68292222,8 L0.240743398,1.65079137 C-0.118678753,1.23146553 -0.0701172162,0.600165549 0.349208627,0.240743398 C0.768534469,-0.118678753 1.39983445,-0.0701172162 1.7592566,0.349208627 L7.7592566,7.34920863 C8.0802478,7.72369836 8.0802478,8.27630164 7.7592566,8.65079137 L1.7592566,15.6507914 C1.39983445,16.0701172 0.768534469,16.1186788 0.349208627,15.7592566 C-0.0701172162,15.3998345 -0.118678753,14.7685345 0.240743398,14.3492086 L5.68292222,8 Z" id="Line"></path>
								</svg>
							  </div>
							</div>
						  </span>
						`;
						// Append it to the container
						document.querySelector('.email-form-container').appendChild(arrowWrapper);
					},
					onFormSubmit: function ($form) {
						document.getElementById('newsletter-arrow').style.display = 'none';
					},
					onFormSubmitted: function ($form, data) {
						window.dataLayer = window.dataLayer || []
						window.dataLayer.push({
							event: "subscriptionCompleted",
						})
					}
				});
			}
		};
		
		document.head.appendChild(script);
	}

	componentWillUnmount() {

	}
	
	render_links(slug) {
		if (this.links) {
			var office_nav = this.query_links(slug)
			return office_nav.items.map(link => (
				<li key={link.title}>
					{link.url === "/birdwatch" ? (
						<a
							href="https://birdwatch.thecharlesnyc.com"
							title="Birdwatch"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span dangerouslySetInnerHTML={{ __html: "Birdwatch" }} />
						</a>
					) : link.url.charAt(0) === "/" ? (
						<Link
							to={link.url}
							title={link.title}
							onClick={this.footerDataLayer}
						>
							<span dangerouslySetInnerHTML={{ __html: link.title }} />
						</Link>
					) : (
						<a
							href={link.url}
							title={link.title}
							target="_blank"
							rel="noopener noreferrer"
						>
							<span dangerouslySetInnerHTML={{ __html: link.title }} />
						</a>
					)}
				</li>
			))
		}
	}
	query_links(query) {
		return this.links.find(item => {
			if (item.slug === query) {
				return item
			}
			return null
		})
	}

	render() {
		return (
			<div className="site-footer" id="site-footer">
				<div className="grid">
					<div className="heron-logo grid-m-6 grid-t-8 grid-d-2">
						<StaticImage
							src="../../assets/images/the-charles-logo.png"
							alt="Logo"
							layout="fixed"
							height={100}
						/>
					</div>
					<div className="list-element grid-m-3 grid-t-8 grid-d-3">
						<h3 className="styling-eyebrow">Offices</h3>
						<ul>{this.render_links("offices-nav")}</ul>
					</div>
					<div className="list-element grid-m-3 grid-t-8 grid-d-3">
						<h3 className="styling-eyebrow">Company</h3>
						<ul>{this.render_links("company-nav")}</ul>
					</div>
					<div className="list-element grid-m-3 grid-t-8 grid-d-3">
						<h3 className="styling-eyebrow">Legal</h3>
						<ul>{this.render_links("legal-nav")}</ul>
					</div>
					<div className="grid-m-3 grid-t-8 grid-d-3">
						<a
							href="https://www.google.com/partners/agency?id=7062024387"
							target="_blank"
						>
							<img src="https://www.gstatic.com/partners/badge/images/2023/PartnerBadgeClickable.svg" />
						</a>
					</div>
					<div className="social-icons grid-m-3 grid-t-8 grid-d-5">
						<h3 className="styling-eyebrow">Follow</h3>
						<ul>{this.render_links("media-nav")}</ul>
					</div>
				</div>
				<div className="grid">
					<div className="email-signup grid-m-6 grid-t-16 grid-d-8 push-d-2">
						<div className="email-form-container"> 
							
						</div>
					</div>
				</div>
				<div className="copyright">
					<p>
						©{new Date().getFullYear()} The Charles Group. <br /> All Rights
						Reserved.
					</p>
				</div>
			</div>
		)
	}
}
